html {
  --argdown-bg-color: #fff;
  --argdown-border-color: #eee;
  --argdown-logo-color: #ccc;
  --argdown-button-bg-color: #3e8eaf;
  --argdown-button-bg-hover-color: #387e9c;
  --argdown-button-border-bottom-color: #38809d;
  --argdown-button-font-color: #fff;
}
argdown-map:not(:defined) [slot="source"] {
  display: none;
}
argdown-map:not(:defined) [slot="map"] {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
argdown-map:not(:defined)[initial-view="source"] [slot="map"] {
  display: none;
}
argdown-map:not(:defined)[initial-view="source"] [slot="source"] {
  display: block;
}
argdown-map:not(:defined) {
  display: block;
  width: 100%;
  border: 1px solid #eee;
  background-color: #fff;
}
/* argdown-map [slot="map"] svg {
  width: 100%;
  height: 100%;
  max-height: 600px;
} */
argdown-map.zooming [slot="map"] svg {
  width: 100%;
  height: 100%;
}
argdown-map pre {
  display: block;
  width: 100%;
  background-color: #fff !important; /* !important to overwrite vscode markdown preview */
  box-sizing: border-box;
  margin: 0;
}
.argdown-figure {
  border: 1px solid #dedede;
  overflow: hidden;
  border-radius: 6px;
  margin: 2rem 0;
}
.argdown-figure figcaption {
  border-top: 1px solid #dedede;
  padding: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}
.argdown-figure argdown-map {
  border: 0;
  height: 100%;
}
